import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
// import NavBar from "./navbar";
// import SideBar from "./navbar/sidebar";
import HomePage from "./home";
import SignInPage from "./signIn";
// import Carousel from "./carousel";
// import Carousel from "./carousel2";
// import { data } from "./carousel2/data";
//import WorkingProcess from "./workingProcess";
//import IntroVideo from "./introVideo";
// import Footer from "./footer";
// import ContactUs from "./contactUs";
import OurServices from "./ourServices";
import Form from "./signUp/Form";
import "./App.css";
import "tailwindcss/dist/base.css";
import "animate.css";
// import Content from "./content";

function App() {
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => {
  //   setIsOpen(!isOpen);
  // };
  return (
    <>
      {/* <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} /> */}
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/signIn" component={SignInPage} exact />
        <Route path="/signUp" component={Form} exact />
        {/* <Route path="/carousel" component={Carousel} exact /> */}
        {/* <Route path="/workingProcess" component={WorkingProcess} exact /> */}
        {/* <Route path="/introVideo" component={IntroVideo} exact /> */}
        <Route path="/ourServices" component={OurServices} exact />
      </Switch>
      {/* <ContactUs id="contactUs" />
      <Footer /> */}
    </>
    // <Content />
    // <Carousel data={data} />
  );
}

export default App;
