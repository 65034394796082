import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";

const useForm = (callback, validate) => {
  const [values, setValues] = useState({
    username: "",
    email: "",
    password: "",
    password2: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { signUp } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validate(values);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      debugger;
      try {
        setLoading(true);
        await signUp(values.email, values.password);
        debugger;
        callback();
      } catch (error) {
        debugger;
        setErrors({ auth: error.code ?? error.message });
      }
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (Object.keys(errors).length === 0 && isSubmitting) {
  //     callback();
  //   }
  // }, [errors]);

  return { handleChange, handleSubmit, loading, values, errors };
};

export default useForm;
