import React from "react";
import { FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import {
  SideBarContainer,
  Icon,
  SideBarWrapper,
  SideBarMenu,
  SideBarLink,
  SideBarRoute,
  SideBtnWrap,
} from "./sideBarElements";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <SideBarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
          <FaTimes />
        </Icon>
        <SideBarWrapper>
          <SideBarMenu>
            <SideBarLink
              to="about"
              onClick={toggle}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              About
            </SideBarLink>
            <SideBarLink
              to="discover"
              onClick={toggle}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Discover
            </SideBarLink>
            <SideBarLink
              to="services"
              onClick={toggle}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Services
            </SideBarLink>
            <SideBarLink
              to="careers"
              onClick={toggle}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Careers
            </SideBarLink>
            <SideBarLink
              to="faq"
              onClick={toggle}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              FAQ
            </SideBarLink>
            <SideBarLink
              to="ourTeam"
              onClick={toggle}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Team
            </SideBarLink>
            <SideBarLink
              to="contactUs"
              onClick={toggle}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Contact Us
            </SideBarLink>

            <SideBarLink
              to="VendorRegistrationBanner"
              onClick={toggle}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Sign Up
            </SideBarLink>
          </SideBarMenu>
          {/* <SideBtnWrap>
            <SideBarRoute to="/signin">Sign In</SideBarRoute>
          </SideBtnWrap> */}
        </SideBarWrapper>
      </SideBarContainer>
    </IconContext.Provider>
  );
};

export default Sidebar;
