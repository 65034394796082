import { Link as LinkReactRouter } from "react-router-dom";
import { Link as LinkReactScroll } from "react-scroll";
import styled from "styled-components";

export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? "#fff;" : "transparent")};
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 20;
  box-shadow: ${({ scrollNav }) =>
    scrollNav ? "0px 0px 10px rgba(0, 0, 0, 0.15)" : "transparent"};

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1200px;
`;

export const NavLogo = styled(LinkReactRouter)`
  /* color: #fff; */
  /* justify-self: flex-start; */
  cursor: pointer;
  /* font-size: 1.5rem; */
  display: flex;
  /* align-items: center; */
  /* margin-left: 210px; */
  /* font-weight: bold; */
  /* text-decoration: none; */

  & img {
    width: ${({ scrollNav }) => (scrollNav ? "120px" : "200px")};
    transition: 0.8s all ease;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavLink = styled(LinkReactScroll)`
  color: ${({ scrollNav }) => (scrollNav ? "#010606" : "#fff")};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-weight: 600;

  &.active {
    border-bottom: 3px solid #01bf71;
    color: #5fb759;
  }

  &:hover {
    transition: all 0.2s ease-in-out;
    /* background: #5fb759; */
    color: #5fb759;
  }
`;

export const NavBtnLink = styled(LinkReactRouter)`
  border-radius: 50px;
  background: ${({ scrollNav }) => (scrollNav ? "#fff" : "transparent")};
  border: ${({ scrollNav }) =>
    scrollNav ? "2px solid #5fb759" : "2px solid #fff"};
  white-space: nowrap;
  padding: 10px 22px;
  color: ${({ scrollNav }) => (scrollNav ? "#5fb759" : "#fff")};
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ scrollNav }) => (scrollNav ? "#5fb759" : "#fff")};
    color: ${({ scrollNav }) => (scrollNav ? "#fff" : "#5fb759")};
  }
`;
