import styled from "styled-components";

export const Section = styled.div`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 0;
    left: 0;
    height: 35%;
    background: linear-gradient(to right, #e9f8ff, #90b2d8, #c1e3ff);
  }

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }

  /* @media only screen and (min-width: 992px) and (max-width: 1199px) {
    &:after {
      height: 45%;
    }
  } */

  /* color: #010606;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#f3f4fe")}; */
`;

// export const Container = styled.div.attrs(() => ({
//   className: "container",
// }))`
//   /* background: #010101;
//   height: auto;
//   width: 100%;
//   z-index: 1;
//   display: grid;
//   margin: 0 auto;
//   padding: 80px 32px;
//   border-radius: 4px;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

//   @media screen and (max-width: 400px) {
//     padding: 32px 32px;
//   } */
// `;

export const Container = styled.div`
  display: grid;
  z-index: 1;
  height: 860px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const Row = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? "'col2 col1'" : "'col1 col2'"};
  /* Must use '\' css class \'' */

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const ContactTitle = styled.div`
  margin-bottom: 150px;

  @media only screen and (min-width: 768px) and (max-width: 991px),
    (max-width: 767px) {
    margin-bottom: 50px;
  }

  & div {
    color: #01bf71;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px !important;
  }

  & h2 {
    font-weight: 600;
    font-size: 35px;
    line-height: 41px;
  }
`;

export const InfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media only screen and (min-width: 768px) and (max-width: 991px),
    (max-width: 767px) {
    margin-bottom: 50px;
  }
`;

export const InfoChildWrap = styled.div`
  display: flex;
  max-width: 100%;
  width: 600px;
  margin-bottom: 30px;
`;

export const InfoIcon = styled.div`
  font-size: 32px;
  color: var(--primary-color);
  margin-right: 24px;
`;

export const InfoMeta = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;

  & p {
    font-size: 15px;
    line-height: 24px;
  }

  & h5 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
  }
`;

export const FormWrap = styled.div.attrs(() => ({
  className: "animate__animated animate__fadeInUp animate__delay-.2s",
}))`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background: white;
  padding: 60px;
  overflow: auto;
  @media (max-width: 767px) {
    padding: 40px 30px;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1399px),
    only screen and (min-width: 992px) and (max-width: 1199px),
    only screen and (min-width: 576px) and (max-width: 767px) {
    padding: 50px 40px;
  }

  /* &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  } */
`;

export const FormTitleH3 = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    .ud-contact-form-title {
      font-size: 24px;
    }
  }
`;

export const FormGroupFields = styled.div`
  margin-bottom: 25px;
  & label {
    display: block;
    font-weight: normal;
    font-size: 14px;
  }
  & input {
    display: block;
    width: 100%;
    padding: 15px 0;
    border: none;
    outline: none;
    border-bottom: 1px solid #f1f1f1;
    resize: none;
  }

  & textarea {
    display: block;
    width: 100%;
    padding: 15px 0;
    border: none;
    outline: none;
    border-bottom: 1px solid #f1f1f1;
    resize: none;
  }

  & input:focus {
    border-color: #5fb759;
  }

  & textarea:focus {
    border-color: #5fb759;
  }

  & p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    color: #f00e0e;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const FormButton = styled.button`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "transparent" : "#5fb759")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#fff" : "#fff")};
  border: 2px solid #fff;
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  font-weight: 600;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#fff" : "transparent")};
    color: ${({ dark }) => (dark ? "#5fb759" : "#010606")};
    border: ${({ dark }) => (dark ? "2px solid #fff" : "2px solid #010606")};
  }
`;
