import React, { useState } from "react";
import {
  CardSlider,
  Card,
  CardImage,
  TextInfo,
  TitleReviewContainer,
  Title,
  Description,
  PrevButton,
  NextButton,
  Controls,
  Content,
  Container,
  HeadingWithControl,
  Heading,
  LinkReactRouter,
  LinkButton,
  StyledModal,
  CloseModalButton,
} from "./servicesElements";
import OurServices from "../ourServices";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import img_MachinedParts from "../../assets/images/AdobeStock_84143584.jpg";
import Img_SheetMetal from "../../assets/images/AdobeStock_127055083.jpg";
import Img_ForgedParts from "../../assets/images/AdobeStock_471939881.jpg";
import Img_CastingParts from "../../assets/images/AdobeStock_216036418.jpg";
import Img_AdvancedEngineeringPoly from "../../assets/images/AdobeStock_74901983.jpg";
import Img_3dPrint from "../../assets/images/AdobeStock_479460855.jpg";

const cards = [
  {
    imageSrc: img_MachinedParts,
    title: "Machined Parts",
    description: `Our Facilities have the most advanced machines, tools, vises, jigs, and an on-machine, in-process inspection system, we can hit tighter tolerances than other machining shops.`,
  },
  {
    imageSrc: Img_SheetMetal,
    title: "Sheet Metal Parts",
    description:
      "Our manufacturing facilities houses all necessary machineries and expert resources with deep experience in various sheet metal services.",
  },
  {
    imageSrc: Img_ForgedParts,
    title: "Forged Parts",
    description:
      "Our manufacturing facilities equipped with highly experienced resources , in-house simulation labs and latest machines like Electro hydraulic hammer, manipulators, and overhead cranes.",
  },
  {
    imageSrc: Img_CastingParts,
    title: "Casting Parts",
    description:
      "Our manufacturing facilities identified based on stringent audits houses latest equipment, which can cast materials including Aluminium, Zinc, Magnesium, Steel and provide finishes including Sandblasting, Polishing, Plating, Powdercoating, and Painting.",
  },
  {
    imageSrc: Img_AdvancedEngineeringPoly,
    title: "Advanced Engineering Polymers",
    description:
      "Our associates include state-of the-Art compounders, World class tool makers and Molders with capability of manufacturing micro moldings to large structural parts.",
  },
  {
    imageSrc: Img_3dPrint,
    title: "3D Printed Parts",
    description:
      "Our capabilities includes technologies such as FDM, SLS,SLM, Vacuum casting. Materials such Plastics, Metals can be 3D Printed at our associate facilities. Faster lead times and fully functional parts can be made using the above technology which are reliable and yet cost effective.",
  },
];

const Services = () => {
  const [modalIsOpen, setModalIsOpen] = useState({ isOpen: false, index: "" });

  const toggleModal = (index = "") =>
    setModalIsOpen((prevState) => ({
      ...prevState,
      index,
      isOpen: !modalIsOpen.isOpen,
    }));
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Container id="services">
      <Content>
        <HeadingWithControl>
          <Heading>Our Services</Heading>
          {!modalIsOpen.isOpen && (
            <Controls>
              <PrevButton onClick={sliderRef?.slickPrev}>
                <ChevronLeftIcon />
              </PrevButton>
              <NextButton onClick={sliderRef?.slickNext}>
                <ChevronRightIcon />
              </NextButton>
            </Controls>
          )}
        </HeadingWithControl>
        {!modalIsOpen.isOpen && (
          <CardSlider ref={setSliderRef} {...sliderSettings}>
            {cards.map((card, index) => (
              <Card key={index}>
                <CardImage imageSrc={card.imageSrc} />
                <TextInfo>
                  <TitleReviewContainer>
                    <Title>{card.title}</Title>
                  </TitleReviewContainer>
                  <Description>{card.description}</Description>
                  {/* <LinkReactRouter
                  to={{
                    pathname: "/ourServices",
                    state: {
                      id: index,
                    },
                  }}
                  onClick={toggleModal}
                >
                  Learn More
                </LinkReactRouter> */}
                  <LinkButton onClick={() => toggleModal(index)}>
                    Learn More
                  </LinkButton>
                </TextInfo>
                {/* <PrimaryButton>Learn More</PrimaryButton> */}
              </Card>
            ))}
          </CardSlider>
        )}
        {/* {modalIsOpen.isOpen && (
          <>
            <CloseModalButton onClick={toggleModal}>
              <CloseIcon tw="w-6 h-6" />
            </CloseModalButton>
            <OurServices
              location={{
                state: {
                  id: modalIsOpen.index,
                },
              }}
            />
          </>
        )} */}
        {modalIsOpen.isOpen && (
          <OurServices
            location={{
              state: {
                id: modalIsOpen.index,
              },
            }}
            toggleClose={toggleModal}
          />
        )}
      </Content>
      {/* <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={modalIsOpen.isOpen}
        onRequestClose={toggleModal}
        shouldCloseOnOverlayClick={true}
      >
        <CloseModalButton onClick={toggleModal}>
          <CloseIcon tw="w-6 h-6" />
        </CloseModalButton>
        <div className="content"> */}
      {/* <OurServices
        location={{
          state: {
            id: modalIsOpen.index,
          },
        }}
        toggleClose={toggleModal}
      /> */}
      {/* </div> */}
      {/* </StyledModal> */}
    </Container>
  );
};

export default Services;
