import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import video from "../../assets/videos/CWRK.Mp4";

import ResponsiveVideoEmbed from "./ResponsiveVideoEmbed.js";

const Container = styled.div`
  ${tw`relative mx-auto -mt-8 bg-center bg-cover`}
  background-image: url("https://images.pexels.com/photos/220201/pexels-photo-220201.jpeg?cs=srgb&dl=pexels-pixabay-220201.jpg&fm=jpg");
`;

const OpacityOverlay = tw.div`absolute inset-0 bg-secondary-100 opacity-25`;

const HeroContainer = tw.div`relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;
const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-green-500 font-medium text-sm`;

const PrimaryAction = tw.div`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;
const FeatureHeading = tw.div`font-bold text-lg text-gray-100`;
const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export const IntroVideo = ({
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
}) => {
  return (
    <Container id={"discover"}>
      <OpacityOverlay />
      <HeroContainer>
        <TwoColumn>
          <LeftColumn>
            <Notification>
              Operating all over Asia, Europe and North America.
            </Notification>
            <Heading>
              <span>Discover</span>
              <br />
              <SlantedBackground>Sourcing 4.0</SlantedBackground>
            </Heading>
            <br />
            <FeatureHeading>
              Watch the video to learn how it's done here at
              <span className="logo-small"> COSWORK</span>
            </FeatureHeading>
          </LeftColumn>
          <RightColumn>
            <StyledResponsiveVideoEmbed url={video} />
          </RightColumn>
        </TwoColumn>
        {/* <TwoColumn /> */}
      </HeroContainer>
    </Container>
  );
};

export default IntroVideo;

//<PrimaryAction>Read Customer Stories</PrimaryAction>
