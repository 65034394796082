import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "./Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "./Buttons.js";
import { ReactComponent as ShieldIcon } from "feather-icons/dist/icons/shield.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as CheckCrcle } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as SunRise } from "feather-icons/dist/icons/sunrise.svg";
import joinOurTeamImage from "../../assets/images/AdobeStock_178564665.jpg";

//import TeamIllustrationSrc from "images/team-illustration-2.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-900`;

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-500`;
const FeatureDescription = tw.div`mt-1 text-sm`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export const UniqueTriats = ({
  subheading = "JOIN OUR TEAM",
  heading = (
    <>
      Make an <span tw="text-primary-500">impact</span> that matters.
    </>
  ),
  description = [
    "We are not conventional. Hence, we are open to people who have broken the conventions and who can think beyond ordinary. We are not worried about your degrees and pedigrees. If you are interested to share your story, please reach out to us at: ",
    <a style={{ color: "blue" }} href="mailto:info@coswork.net">
      info@coswork.net
    </a>,
  ],
  primaryButtonText = "Start Now",
  primaryButtonUrl = "",
  features = null,
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: ShieldIcon,
      title: "Great Place to Work",
      description:
        "We have the best professional marketing people across the globe just to work with you.",
    },
    {
      Icon: MoneyIcon,
      title: "We value Ethics",
      description:
        "We value ethics, encourage innovation and connect you to leaders who help you grow with the company",
    },
    // {
    //   Icon: CheckCrcle,
    //   title: "Assured Quality",
    //   description:
    //     "We promise to offer you the best rate we can - at par with the industry standard.",
    // },
    // {
    //   Icon: SunRise,
    //   title: "Environment and Social Responsibility",
    //   description:
    //     "We promise to offer you the best rate we can - at par with the industry standard.",
    // },
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container id={"careers"}>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={joinOurTeamImage} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIconContainer>
                    {<feature.Icon />}
                  </FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                    <FeatureDescription>
                      {feature.description}
                    </FeatureDescription>
                  </FeatureText>
                </Feature>
              ))}
            </Features>
            {/* <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

export default UniqueTriats;
