import React, { useEffect, useState, useRef } from "react";
import emailImage from "../../assets/images/contact-info-02.png";
import globeImage from "../../assets/images/contact-info-03.png";
// import TrackVisibility from "react-on-screen";
import {
  Section,
  Container,
  Row,
  Column1,
  Column2,
  ContactTitle,
  InfoWrap,
  InfoChildWrap,
  InfoIcon,
  InfoMeta,
  FormWrap,
  FormTitleH3,
  FormGroupFields,
  BtnWrap,
  FormButton,
} from "./contactUsElements";
import validate from "./validateInfo";
import { sendEmailAdmin, sendEmailCustomer } from "../../api/contactFormAPI";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// import emailjs from "emailjs-com";

const ContactUs = ({ id }) => {
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
    toggleSent: false,
  });
  const form = useRef();
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    debugger;
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneNumberChange = (phone) => {
    setValues((prevState) => ({
      ...prevState,
      phone: phone ?? "",
    }));
  };

  const handleToggle = () => {
    setValues((prevState) => ({
      toggleSent: !prevState.toggleSent,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validate(values);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      sendEmailAdmin(values)
        .then(
          sendEmailCustomer(values).then(
            () => {
              setValues((prevState) => ({
                ...prevState,
                toggleSent: !prevState.toggleSent,
              }));
            },
            (error) => {
              console.log(error.text);
            }
          )
        )
        .catch((error) => console.log(error.text));
    }
  };

  return (
    <>
      <Section id={id}>
        <Container>
          <Row>
            <Column1>
              <ContactTitle>
                <div>CONTACT US</div>
                <h2>
                  Let’s hear from you <br />
                  Send us your Inquiry
                </h2>
              </ContactTitle>
              <InfoWrap>
                <InfoChildWrap>
                  <InfoIcon>
                    <img src={globeImage} alt="phone" />
                  </InfoIcon>
                  <InfoMeta>
                    <h5>India Office</h5>
                    <p>
                      22, The Palms, Suite D, 3rd Floor,
                      <br /> Ramanathan Street, Kilpauk, Chennai - 600010
                    </p>
                    <p>Ph: +91-9940444344</p>
                  </InfoMeta>
                </InfoChildWrap>
                <InfoChildWrap>
                  <InfoIcon>
                    <img src={globeImage} alt="phone" />
                  </InfoIcon>
                  <InfoMeta>
                    <h5>USA Office</h5>
                    <p>43 Cedar Crest Lane Suffield CT - 06078</p>
                    <p>Ph: +1 (860) 833 7445</p>
                  </InfoMeta>
                </InfoChildWrap>
                <InfoChildWrap>
                  <InfoIcon>
                    <img src={emailImage} alt="phone" />
                  </InfoIcon>
                  <InfoMeta>
                    <h5>How Can We Help?</h5>
                    <p>info@coswork.net</p>
                  </InfoMeta>
                </InfoChildWrap>
              </InfoWrap>
            </Column1>
            <Column2>
              {/* <TrackVisibility once>
                {({ isVisible }) =>
                  isVisible && ( */}
              <FormWrap>
                {!values.toggleSent && (
                  <>
                    {" "}
                    <FormTitleH3>Send us a Message</FormTitleH3>
                    <form ref={form} onSubmit={handleSubmit} noValidate>
                      <FormGroupFields>
                        <label htmlFor="fullName">Full Name*</label>
                        <input
                          required
                          type="text"
                          name="fullName"
                          value={values.fullName}
                          onChange={handleChange}
                          placeholder="Enter full name"
                        />
                        {errors.fullName && <p>{errors.fullName}</p>}
                      </FormGroupFields>
                      <FormGroupFields>
                        <label htmlFor="email">Email*</label>
                        <input
                          required
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          placeholder="Enter email address"
                        />
                        {errors.email && <p>{errors.email}</p>}
                      </FormGroupFields>
                      <FormGroupFields>
                        <label htmlFor="phone">Phone*</label>
                        <PhoneInput
                          required
                          type="text"
                          name="phone"
                          value={values.phone}
                          onChange={handlePhoneNumberChange}
                          placeholder="Enter phone number"
                        />
                        {errors.phone && <p>{errors.phone}</p>}
                      </FormGroupFields>
                      <FormGroupFields>
                        <label htmlFor="message">Message*</label>
                        <textarea
                          required
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                          rows={1}
                          placeholder="Enter your message here"
                        />
                        {errors.message && <p>{errors.message}</p>}
                      </FormGroupFields>
                      <FormButton type="submit">Send Message</FormButton>
                    </form>{" "}
                  </>
                )}
                {values.toggleSent && (
                  <>
                    <FormGroupFields>
                      <h5 style={{ color: "#5fb759", fontWeight: "bold" }}>
                        Thank you for contacting us. Our expert team is working
                        on your inquiry. We will get back to you shortly
                      </h5>
                    </FormGroupFields>
                    <FormButton type="button" onClick={handleToggle}>
                      Okay
                    </FormButton>
                  </>
                )}
              </FormWrap>
              {/* )
                }
              </TrackVisibility> */}
            </Column2>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default ContactUs;
