import axios from "axios";
import * as methods from "./methods";
import * as endPoints from "./endPoints";

const axiosInstance = axios.create();

export default async (props) => {
  try {
    const { url, params, data, method } = props;
    axiosInstance.defaults.baseURL = endPoints.baseURL;
    axiosInstance.defaults.timeout = 0;
    let resp;
    switch (method) {
      case methods.DELETE:
        let param = "";
        for (var key in params) {
          param += "/" + param[key];
        }
        resp = await axiosInstance.delete(url + param);
        break;
      case methods.PUT:
        resp = await axiosInstance.put(url, { ...params });
        break;
      case methods.POST:
        resp = await axiosInstance.post(url, { ...data }, { ...params });
        break;
      default:
        resp = await axiosInstance.get(url, {
          ...(params && {
            params,
          }),
        });
    }
    return resp;
  } catch (err) {
    throw err;
  }
};
