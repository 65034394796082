/* A simple iframe wrapper that makes 16:9 responsive embed */
import React from "react";
import videoPoster from "../../assets/images/coswork-poster.png";

export const Video = ({ url, background = "black", className = "video" }) => {
  return (
    <div
      className={className}
      style={{
        position: "relative",
        background: background,
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      {/* <iframe
        title="Embeded Video"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={url}
        frameBorder="0"
      /> */}
      <video controls poster={videoPoster}>
        <source src={url} type="video/mp4" />
        {/* <source src="mov_bbb.ogg" type="video/ogg" /> */}
        Your browser does not support HTML video.
      </video>
    </div>
  );
};

export default Video;
