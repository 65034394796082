import apiHandler from "../utils/handler";
import * as endPoints from "../utils/endPoints";
import * as methods from "../utils/methods";

export const sendEmailCustomer = async (data) => {
  const props = {
    url: endPoints.SEND_EMAIL_CUSTOMER,
    data,
    method: methods.POST,
  };
  const response = await apiHandler(props);
  return response.data;
};

export const sendEmailAdmin = async (data) => {
  const props = {
    url: endPoints.SEND_EMAIL_ADMIN,
    data,
    method: methods.POST,
  };
  const response = await apiHandler(props);
  return response.data;
};
