import React, { useState } from "react";
import "./Form.css";
import FormSignup from "./FormSignup";
import FormSuccess from "./FormSuccess";
import image from "../../assets/images/svg-3.svg";
import { AuthProvider } from "../context/AuthContext";

const Form = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }
  return (
    <AuthProvider>
      <div className="form-container">
        <div className="form-content-left">
          <img className="form-img" src={image} alt="spaceship" />
        </div>
        {!isSubmitted ? (
          <FormSignup submitForm={submitForm} />
        ) : (
          <FormSuccess />
        )}
      </div>
    </AuthProvider>
  );
};

export default Form;
