import React, { useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "./dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "./Headings.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import Img_MachinedParts from "../../assets/images/AdobeStock_320542224.jpg";
import Img_SheetMetal from "../../assets/images/AdobeStock_386606050.jpg";
import Img_ForgedParts from "../../assets/images/AdobeStock_322507117.jpg";
import Img_CastingParts from "../../assets/images/AdobeStock_228787588.jpg";
import Img_3dParts from "../../assets/images/AdobeStock_449308752.jpg";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center sm:border shadow-md rounded-5xl bg-gray-200`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-900`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export const OurServices = ({ location, toggleClose }) => {
  //https://media.istockphoto.com/photos/drilling-picture-id182424579

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const cards = [
    {
      imageSrc: Img_MachinedParts,
      subtitle: "Machined Parts",
      title: "Machined Parts",
      description: `We understand that when you are looking for precision machined parts you cannot afford any compromises if you want parts that meet your quality requirements. 
        We provide you with a wide range of precision CNC machining services, an experienced team who understands the best approach to different projects using 3-, 4-, and 5-axis CNC machines. 
        We undertake CNC milling, CNC Turning, CNC Laser, CNC Plasma Cutting, EDM, wire EDM, and surface grinding while creating your prototypes and parts, allowing you to focus on bringing your product to the market.
        Our Facilities have the most advanced machines, tools, vises, jigs, and an on-machine, in-process inspection system, we can hit tighter tolerances than other machining shops.
        In addition our two layered QC system using a wide range of Inspection equipment including laser projectors, CMMs, and 3D scanners, ensure that all our manufactured parts meet the highest standards
        Our Manufacturing facilities are suppliers to leading Automotives OEM’s from Japan, Korea, Europe and India 
        `,
      url: "",
    },

    {
      imageSrc: Img_SheetMetal,
      subtitle: "Sheet Metal Parts",
      title: "Sheet Metal Parts",
      description: `In order to make robust functional parts using sheet metal, you need expertise in multiple processes, which manipulates the sheet metal in different ways.
        Our Manufacturing facilities houses all necessary machineries and expert resources with deep experience in Assembling, Blending, Blanking, Cutting, Die Cutting, Fastening, Finishing, Galvanizing, Glazing, Hydroforming, Metal Punching, Milling, Welding, Roll forming, Shrinking, Spinning, Stamping Stretching, Tucking, Welding. 
          Our Manufacturing Facilities supply sheet metal parts to specialist industries like automotive, aerospace, energy, and robotics.
        `,
      url: "",
    },
    {
      imageSrc: Img_ForgedParts,
      subtitle: "Forged Parts",
      title: "Forged Parts",
      description: [
        `We understands that more than 80% of rejection and rework in forging are due to forging defects like overlap, underfilling, pitting, foreign body and shop scrap. In order to overcome the defects proper use of anti-scale coating, venting process to prevent the under filling, the simulation software for determining the material flow, proper lubricant instead of furnace oil etc.
        Our manufacturing facilities equipped with highly experienced resources , in-house simulation labs and latest machines  like Electro hydraulic hammer, manipulators, overhead cranes
         Our manufacturing facilities have experience in making forged parts of Automotive, Off-highway equipments, Oilfield equipments, Marine Industry, etc.`,
        // <ol>
        //   <a
        //     style={{ color: "blue" }}
        //     href="https://www.ijert.org/study-of-various-factors-related-to-quality-in-forging-industry"
        //   >
        //     https://www.ijert.org/study-of-various-factors-related-to-quality-in-forging-industry
        //   </a>
        // </ol>,
        // <ol>
        //   <a
        //     style={{ color: "blue" }}
        //     href="https://www.jetir.org/papers/JETIR1407054.pdf"
        //   >
        //     https://www.jetir.org/papers/JETIR1407054.pdf
        //   </a>
        // </ol>,
      ],
      url: "",
    },
    {
      imageSrc: Img_CastingParts,
      subtitle: "Casting Parts",
      title: "Casting Parts",
      description: `Some of the most common defects observed in Casting are Gas Porosity, Shrinkage, Metallurgical defects, etc.. A properly designed casting, a properly prepared mould and correctly malted metal should result in a defect free casting.
      Our manufacturing facilities identified based on stringent audits houses latest equipment, which can cast materials including Aluminium, Zinc, Magnesium, Steel and provide finishes including Sandblasting, Polishing, Plating, Powdercoating, Painting, 
      Our Manufacturing partners have experience in providing Casting parts to Machine Tool Beds, and other complex geometries across industries
      `,
      url: "",
    },
    {
      imageSrc:
        "https://media.istockphoto.com/photos/modern-production-line-with-machinery-picture-id664589690",
      subtitle: "Advanced Engineering Polymers",
      title: "Advanced Engineering Polymers",
      description: `We are a one stop shop for all the Polymeric products which includes, Molded parts, 
      Machined parts with highest level of precision. We are capable of handling material from POLYPROPYLENE  To PEEK, POLYIMIDE etc touching the entire spectrum of polymers from commodity to engineering plastics to Super specialty polymers.  Our associates include state-of the-Art compounders, World class tool makers and Molders with capability of manufacturing micro moldings to large structural parts.  
      `,
      url: "",
    },
    {
      imageSrc: Img_3dParts,
      subtitle: "3D Printed Parts",
      title: "3D Printed Parts",
      description: `Our capabilities includes technologies such as FDM, SLS,SLM, Vacuum casting. Materials such Plastics, Metals can be 3D Printed at our associate facilities.  Faster lead times and fully functional parts can be made using the above technology which are reliable and yet cost effective.
      `,
      url: "",
    },
  ];

  return (
    <Container>
      {/* <SingleColumn> */}
      {/* <HeadingInfoContainer>
          <HeadingTitle>Our Services</HeadingTitle>
          <HeadingDescription>
            Here are some of the most popular services provided at COSWORK
          </HeadingDescription>
        </HeadingInfoContainer> */}

      <Content>
        {cards.map(
          (card, i) =>
            i === location.state.id && (
              <Card key={i} reversed={i % 2 === 1}>
                <Image imageSrc={card.imageSrc} />
                <Details>
                  {/* <Subtitle>{card.subtitle}</Subtitle> */}
                  <Title>{card.title}</Title>
                  <Description>{card.description}</Description>
                  <Link onClick={toggleClose}>
                    <CloseIcon tw="w-10 h-10" />
                  </Link>
                </Details>
              </Card>
            )
        )}
      </Content>
      {/* </SingleColumn> */}
      {/* <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 /> */}
    </Container>
  );
};

export default OurServices;
