import React, { useState, useEffect, useRef } from "react";
import { Button } from "../buttonElements";
import InfoSection from "../../components/infoSection";
import Services from "../../components/services";
// import About from "../../components/about/AboutPage";
import styled, { css } from "styled-components/macro";
import { IoArrowForward, IoArrowBack } from "react-icons/io5";
import {
  homeObjOne,
  homeObjTwo,
  homeObjThree,
  homeObjFour,
} from "../../components/infoSection/data";
import {
  HomeContainer,
  HomeBg,
  VideoBg,
  ImageBg,
  HomeContent,
  HomeH1,
  HomeP,
  HomeBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./homePageElements";
import AboutUs from "components/about";
import FAQ from "components/faq";
import UniqueTriats from "components/uniqueTriats";
// import OurServices from "../ourServices";
import IntroVideo from "../introVideo";
import VendorRegistrationBanner from "components/vendorRegistrationBanner";
import Testimonials from "components/testimonial";
import NavBar from "components/navbar";
import SideBar from "components/navbar/sidebar";
import Footer from "components/footer";
import ContactUs from "components/contactUs";
import Features from "components/features";
import OurTeam from "components/ourTeam";
import Hero from "components/hero";
// import WorkingProcess from "../workingProcess";

import video1 from "../../assets/videos/trade-route-concept-animation-major-location-destination-SBV-300316368-HD.mp4";
import video2 from "../../assets/videos/timelapse-of-a-large-manufacturing-facility-SBV-318738238-HD.mp4";
import video3 from "../../assets/videos/metalworking-cnc-milling-machine-cutting-metal-modern-processing-technology-SBV-302438389-HD.mp4";
import video4 from "../../assets/videos/cnc-laser-cutting-of-metal-modern-industrial-technology-SBV-314898666-HD.mp4";
import video5 from "../../assets/videos/close-up-view-of-printing-plastic-model-on-a-3d-printer-in-process-SBV-338489580-HD.mp4";

const SliderButtons = styled.div`
  position: absolute;
  bottom: 150px;
  right: 50px;
  display: flex;
  z-index: 10;

  @media screen and (max-width: 768px) {
    bottom: 100px;
    right: 50px;
  }

  @media screen and (max-width: 480px) {
    bottom: 50px;
    right: 50px;
  }
`;

const arrowButtons = css`
  width: 50px;
  height: 50px;
  color: #fff;
  cursor: pointer;
  background: transparent;
  border-radius: 50px;
  padding: 10px;
  margin-right: 1rem;
  user-select: none;
  transition: 0.3s;

  &:hover {
    background: #fff;
    color: #5fb759;
    transform: scale(1.05);
  }
`;

const PrevArrow = styled(IoArrowBack)`
  ${arrowButtons}
`;

const NextArrow = styled(IoArrowForward)`
  ${arrowButtons}
`;

const HomePage = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [current, setCurrent] = useState(0);
  const length = SliderData.length;
  const timeout = useRef(null);

  useEffect(() => {
    const nextSlide = () => {
      setCurrent(current === length - 1 ? 0 : current + 1);
    };

    timeout.current = setTimeout(nextSlide, 9000);

    return function () {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [current, length]);

  const nextSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <NavBar toggle={toggle} />
      {/* <Hero /> */}
      <HomeContainer>
        {SliderData.map((slide, index) => (
          <>
            {index === current && (
              <>
                <HomeBg>
                  <VideoBg autoPlay muted playsInline type="video/mp4">
                    <source src={slide.src} type={"video/mp4"} />
                  </VideoBg>
                  {/* <ImageBg src={slide.src} alt={""} /> */}
                </HomeBg>
                <HomeContent>
                  <HomeH1>{slide.title}</HomeH1>
                  <HomeP>{slide.description}</HomeP>
                  <HomeBtnWrapper>
                    <Button
                      to="contactUs"
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      primary="true"
                      dark="true"
                      onMouseEnter={onHover}
                      onMouseLeave={onHover}
                    >
                      Send an Enquiry{" "}
                      {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                  </HomeBtnWrapper>
                </HomeContent>
                <SliderButtons>
                  <PrevArrow onClick={prevSlide} />
                  <NextArrow onClick={nextSlide} />
                </SliderButtons>
              </>
            )}
          </>
        ))}
        ;
      </HomeContainer>
      {/* <InfoSection {...homeObjOne} /> */}
      <AboutUs />
      {/* <OurServices /> */}
      <IntroVideo />
      {/* <WorkingProcess /> */}
      {/* <InfoSection {...homeObjTwo} /> */}
      <Services />
      {/* <InfoSection {...homeObjFour} /> */}
      <Features />
      <UniqueTriats />
      {/* <Testimonials /> */}
      <FAQ />
      <OurTeam />
      {/* <InfoSection {...homeObjThree} /> */}
      {/* <GetStartedCTA /> */}
      <ContactUs id="contactUs" />
      <VendorRegistrationBanner id="vendorRegistrationBanner" />
      <Footer />
    </>
  );
};

const headerText = {
  title: [
    <span className="logo-small">COSWORK</span>,
    ` - Your supplier partner meeting all your engineering needs`,
  ],
  description: [
    <span className="logo-small">COSWORK</span>,
    ` translates your engineering needs into components requiring high precision, world-class quality and unlimited Scale`,
  ],
};

const SliderData = [
  {
    ...headerText,
    src: video1,
    // "https://media.istockphoto.com/videos/machine-lathe-video-id601041394",
  },
  {
    ...headerText,
    src: video2,
    // "https://media.istockphoto.com/videos/machine-lathe-video-id601041394",
  },
  {
    ...headerText,
    src: video3,
    // "https://media.istockphoto.com/videos/automated-production-with-cnc-process-and-laser-machine-for-cut-metal-video-id1018688304",
  },
  {
    ...headerText,
    src: video4,
    // "https://media.istockphoto.com/videos/manufacturing-printer-during-work-video-id1153031730",
  },
  {
    ...headerText,
    src: video5,
    // "https://media.istockphoto.com/videos/manufacturing-printer-during-work-video-id1153031730",
  },
  // {
  //   title:
  //     "4th Generation Manufacturing – Revolutionizing the Sourcing Paradigm",
  //   description: [
  //     `At `,
  //     <span className="logo-small">COSWORK</span>,
  //     ` we disrupt sourcing by building a global Industrial network
  //     of facilities which can produce engineering parts, assemblies and
  //     sub-assemblies for manufacturing firms.`,
  //   ],
  //   src: video1,
  //   // "https://media.istockphoto.com/videos/machine-lathe-video-id601041394",
  // },
  // {
  //   title: "Democratizing Sourcing, leveraging Industry 4.0 technologies",
  //   description: [
  //     <span className="logo-small">COSWORK</span>,
  //     ` is your engineering
  //  partner, which leverage a network of world-class manufacturing
  //  organizations, asynchronously connected through a robust digital
  //  platform. We call it Sourcing 4.0`,
  //   ],
  //   src: video2,
  //   // "https://media.istockphoto.com/videos/machine-lathe-video-id601041394",
  // },
  // {
  //   title: "Your partner for Fast and Reliable Manufacturing Solution",
  //   description: [
  //     <span className="logo-small">COSWORK</span>,
  //     ` is your engineering
  //  partner, which leverage a network of world-class manufacturing
  //  organizations, asynchronously connected through a robust digital
  //  platform. We call it Sourcing 4.0`,
  //   ],
  //   src: video3,
  //   // "https://media.istockphoto.com/videos/automated-production-with-cnc-process-and-laser-machine-for-cut-metal-video-id1018688304",
  // },
  // {
  //   title:
  //     "Our Global Network of Manufacturing Facilities to meet your procurement needs",
  //   description: [
  //     <span className="logo-small">COSWORK</span>,
  //     ` is your engineering
  //  partner, which leverage a network of world-class manufacturing
  //  organizations, asynchronously connected through a robust digital
  //  platform. We call it Sourcing 4.0`,
  //   ],
  //   src: video4,
  //   // "https://media.istockphoto.com/videos/manufacturing-printer-during-work-video-id1153031730",
  // },
  // {
  //   title:
  //     "Our Global Network of Manufacturing Facilities to meet your procurement needs",
  //   description: [
  //     <span className="logo-small">COSWORK</span>,
  //     ` is your engineering
  //  partner, which leverage a network of world-class manufacturing
  //  organizations, asynchronously connected through a robust digital
  //  platform. We call it Sourcing 4.0`,
  //   ],
  //   src: video5,
  //   // "https://media.istockphoto.com/videos/manufacturing-printer-during-work-video-id1153031730",
  // },
];

export default HomePage;
